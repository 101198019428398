@import "reset-css";

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;

  background: #2c3a47;
  color: #ffffff;

  font-family: "Odibee Sans", sans-serif;
  font-size: 18px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
